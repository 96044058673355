<template>
  <div class="signLogin">
    <div class="bg"></div>
    <div class="sign-bg">
      <div class="sign-main">
        <img
          src="@/assets/sign-a.png"
          class="sign-a pointer"
          @click="toReset('/index')"
        />
        <div class="sign-form flex between">
          <img src="@/assets/sign-b.png" class="sign-b" />
          <div class="forms">
            <div class="forms-tit">账户登录</div>
            <div class="input-div">
              <input
                v-model="username"
                placeholder="请输入手机号/账号"
                @keydown="addByEnterKey"
              />
            </div>
            <div class="input-div">
              <input
                @keydown="addByEnterKey"
                v-model="password"
                type="password"
                placeholder="请输入密码"
              />
            </div>
            <div class="input-div2 flex between">
              <div class="code-div">
                <input
                  v-model="code"
                  placeholder="请输入验证码"
                  @keydown="addByEnterKey"
                />
              </div>
              <img :src="codeImg" class="pointer" @click="getCodeImg" />
            </div>
            <div class="tips">
              <span class="pointer" @click="toReset('/signReset')"
                >忘记密码</span
              >
              |
              <span class="pointer" @click="toReset('/signResign')"
                >注册账户</span
              >
            </div>
            <div class="login-btn pointer" @click="toLogin">登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userLogin } from "@/api/apiData";
export default {
  data() {
    return {
      username: "",
      password: "",
      code: "",
      codeImg: "",
    };
  },
  mounted() {
    this.$bus.emit("navS", 0);
    this.getCodeImg();
  },

  methods: {
    getCodeImg() {
      let url =
        "https://gxrcexam.nccloudmedia.com/index.php/api/common/captcha?rand=" +
        Math.random();
      // let url =
      //   "http://192.168.1.162:8085/api/api/common/captcha?rand=" +
      //   Math.random();
      this.codeImg = url;
    },
    toReset(u) {
      this.$router.push(u);
    },
    toLogin() {
      let type = this.$route.query.type;
      userLogin({
        captcha: this.code,
        mobile: this.username,
        password: this.password,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "登陆成功",
          });
          localStorage.setItem("token", res.data.token);
          if (type == 1) {
            this.$router.push({
              path: "/userInfo",
              query: { type: 1 },
            });
          } else {
            this.$router.push("/exam");
          }
        } else {
          this.getCodeImg()
          this.$message.error(res.msg);
        }
      });
    },
    addByEnterKey(e) {
      if (e.keyCode == 13) {
        this.toLogin();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #539fea;
  z-index: -1;
}
.sign-bg {
  width: 100%;
  .sign-main {
    width: 1073px;
    margin: 80px auto;
    .sign-a {
      width: 50%;
      margin-bottom: 30px;
    }
  }
  .sign-form {
    width: 100%;
    background: white;
    padding: 50px 0;
    .sign-b {
      width: 390px;
      margin-left: 80px;
    }
    .forms {
      width: 40%;
      text-align: left;
      margin-right: 80px;
      .forms-tit {
        font-size: 20px;
      }
      .input-div {
        padding: 10px;
        border-bottom: solid 1px #d1d1d1;
        margin-top: 30px;
      }
      input {
        border: none;
        font-size: 16px;
      }
      .input-div2 {
        margin: 30px 0 20px;
        .code-div {
          width: 48%;
          border: solid 1px #d1d1d1;
          padding: 10px;
        }
        input {
          width: 100%;
          height: 100%;
        }
        img {
          width: 45%;
        }
      }
      .tips {
        color: #838282;
        font-size: 14px;
        text-align: right;
      }
      .login-btn {
        width: 100%;
        background: #0089e1;
        color: white;
        font-size: 20px;
        text-align: center;
        line-height: 49px;
        border-radius: 4px;
        margin: 40px 0 20px;
      }
    }
  }
}
</style>